import { Layout } from "../components/layout";
import { Col, Row, Select, Form, Input, notification, Spin } from "antd";
import { useDispatch, useSelector,  } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect } from "react";
import { Navbar } from "../components/navbar";

import { Png } from '../assets/png'
import { updatePaymentStatus } from "../redux/training";


const VerificationPage = () => {

    const training = useSelector((state) => state.training);
    const { isLoading, isError, errorMessage, isSuccess} = training;

    const antIcon = <LoadingOutlined style={{ fontSize: 24,color: 'white' }} spin />;

    const dispatch = useDispatch();

    const handleSubmit = (values) => {
        const formData = new FormData()
        formData.append('email', values.email)
        formData.append('reference', values.payment_reference)
        formData.append('token', values.token)
        formData.append('action', 'update_payment')
        dispatch(updatePaymentStatus(formData))
    }



    useEffect(() => {

       if(!isLoading && isError){
        notification.open({
            
            message: errorMessage,
            type: 'error',
            className: 'custom-class',
            style: {
                width: 600,
            },
        });
       }

       if(isSuccess){
        notification.open({
            
            message: isSuccess,
            type: 'success',
            className: 'custom-class',
            style: {
                width: 600,
            },
        });
       }

    }, [isError, isLoading, errorMessage, isSuccess]);
    return (
        <>
            {/* <Layout> */}


            <div className="container">
            <Navbar />
        </div>


                <section style={{ height: '80px' }}></section>

                <main>
                    <div className="container margin_60" >




                        <div className="row verification-container" >

                            <div className="col-md-12">
                                <div className="title">
                                    <h2 style={{ textAlign: 'center', fontSize: '28px', paddingTop: '20px' }}>Payment Verification</h2>
                                    <p style={{ textAlign: 'center', padding: '0 10px 0 10px' }}>Please fill in all fields to procced with verification </p>
                                </div>
                                <Form onFinish={handleSubmit} layout="vertical">
                                    <div className="row">


                                        <div className="col-md-12">
                                            <Form.Item label="Email Address" name='first_name' rules={[
                                                {
                                                    required: true,
                                                    message: 'Email address is required',
                                                },


                                            ]}
                                            >
                                                <Input size="large" placeholder="Enter your first name" />
                                            </Form.Item>
                                        </div>


                                    </div>


                                    <div className="row">


                                        <div className="col-md-12">
                                            <Form.Item label="Verification Code" name='verification_code'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input size="large" placeholder="Enter the verification code sent to your email " />
                                            </Form.Item>
                                        </div>

                                    </div>



                                    <div className="row">
                                        <div className="col-md-12">
                                            <Form.Item label="Payment Reference (RRR)" name='payment_reference' rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]} >
                                                <Input size="large" placeholder="Enter payment (RRR)" />

                                            </Form.Item>

                                        </div>



                                    </div>

                                    <div className="row" style={{ marginTop: '40px' }}>
                                        <div className="col-md-12">
                                            <button type="submit" className="ls-l button_intro " style={{ top: '80%', left: 60, whiteSpace: 'nowrap' }} data-ls="durationin:2000;delayin:1300;easingin:easeOutElastic;"> {isLoading ? <Spin indicator={antIcon}  /> : 'Verify'}</button>

                                        </div>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>




                </main>


            {/* </Layout> */}

        </>
    )
}


export default VerificationPage;