import { Layout } from "../components/layout";
import { Png } from '../assets/png'


const AboutUsPage = () => {
    const { Slide1, Slide2, Slide3, Consultancy, Training, Training2, Surface } = Png;
    return (
        <>


            <Layout>

                <section className="parallax_window_in short" data-parallax="scroll" data-image-src={Slide3} data-natural-width={1400} data-natural-height={350}>
                    <div id="sub_content_in">
                        <div className="container">
                            <h1>About Us</h1>
                            <p>"Pedekson Global Limited"</p>
                        </div>
                    </div>
                </section>


                <main>
                    <div className="container margin_60">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <div id="intro">
                                    <h2>"PEDEKSON GLOBAL since 2016"</h2>
                                    <p className="lead">
                                        PEDEKSON GLOBAL NIGERIA LIMITED is engaged in the business of  Surface Well Testing, Consultancy and Training services
                                    </p>
                                </div>
                            </div>
                        </div>{/* End row */}
                        <hr />
                        <div className="row">
                            <div className="col-sm-4">
                                <h3>Some words about us</h3>
                                <p>
                                    PEDEKSON has been providing SURFACE WELL TESTING services to the Nigerian Oil and Gas sector since
                                    2016. Our Well Test Package is designed to meet all industry safety and quality standards for sensitive
                                    operation. We plan and design solutions to meet client’s needs, while ensuring we deliver timely,
                                    reliable and accurate data and reports to achieve the highest operating efficiency available.
                                </p>

                                <h4>Mission</h4>
                                <p>
                                    To provide professional environmental management services, construction services and general engineering services to the best satisfaction of our clients. Ensure zero down line to the client as a result of our operations by ensuring that our equipment’s are always in good condition. To promote local content policy in the country by making use of made in Nigeria goods to complete our work.
                                </p>

                                <h4>Core Values (FIRST)</h4>

                                <p>Our core value is based on this acronym's. FIRST meaning financial discipline, we are of integrity and honest, we respect our employee, customers and suppliers, safety is our utmost priority and technically of good leadership</p>

                                <h4>Objectives</h4>
                                <p>
                                    The three main aspects of PEDEKSON GLOBAL NIGERIA LIMITED are THINK which is the planning process, START which is the monitoring process and FOCUS which is the improvement process. All these are geared toward achieving the company’s objectives and keeping to her policies.                                </p>

                                <h4>Effective Team Work</h4>
                                <p>
                                    Our core value is based on this acronym’s. FIRST meaning financial discipline, we are of integrity and honest, we respect our employee, customers and suppliers, safety is our utmost priority and technically of good leadership.</p>
                            </div>
                            <div className="col-sm-7 col-sm-offset-1">
                                <ul className="feat" id="about">
                                    <li>
                                        <i className="icon_check_alt2" />
                                        <h4>Professional Service</h4>
                                        <p>
                                            We are competitive in the industry and have satisfied our client by our proven ability to serve, even in
                                            the toughest operating conditions. This is possible through the Engineering management services (EMS)
                                            of PEDEKSON GLOBAL LIMITED. With a team of competent staff, with the right expertise and visionary
                                            management, PEDEKSON has all it takes to make an indelible impact in the oil and gas industry. Our goal
                                            is to strive to meet and exceed the International oil field management standards as an indigenous oil
                                            and gas services company. And to conduct business based on integrity and trust acceptable to our
                                            clients and business partners.
                                        </p>
                                    </li>
                                    <li>
                                        <i className="icon_check_alt2" />
                                        <h4>Personnel Safety</h4>
                                        <p>
                                            Safety of personnel, equipment and the environment is paramount, and taken seriously at Pedekson.
                                            Daily in-house QHSE briefs and workshops, as well as industry required external certification is routine.
                                            Engineers and adequately trained and prepared for operation. A job hazard analysis is always part of our
                                            pre-job preparation and reviewed by management before personnel or equipment leave base to
                                            location.
                                        </p>
                                    </li>

                                    <li>
                                        <i className="icon_check_alt2" />
                                        <h4>Service Warranty</h4>
                                        <p>
                                            PEDEKSON GLOBAL NIGERIA LIMITED, upholds with high esteem the supreme principles of customer satisfaction through innovative and efficient service delivery at all times. We are guided by the universal ethics of quality control, health, safety and environmental responsiveness. In PEDEKSON GLOBAL, we are poised in creating greener world.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>{/* End row */}
                       


                    </div>{/* End container */}

                </main>{/* End main */}





            </Layout>

        </>
    )
}


export default AboutUsPage;