
import React from "react";
import HomePage from './pages/home-page'
import Training from './pages/trainings'
import VerificationPage from './pages/verify-application'

import 'antd/dist/antd.css'
import './App.css'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom"
import AboutUsPage from "./pages/about-us-page";
import SurfaceWellTestPage from "./pages/surfacewelltest-page";

function App() {
  return (
   <Router>
      <Routes>
          <Route path="/" element={<HomePage />}  /> 
          <Route path="/training-services" element={<Training />}  /> 
          <Route path="/about-us" element={<AboutUsPage />}  /> 
          <Route path="/surface-well-testing" element={<SurfaceWellTestPage />} />
          <Route path="/verify" element={<VerificationPage />} />
         
        </Routes>
   </Router>
  );
}

export default App;
