import { Layout } from "../components/layout";
import { Png } from "../assets/png";
import ReadMoreReact from "read-more-react";

import {
	Grid,
	GridItem,
	Box,
	Text,
	Button,
	Image,
	HStack,
	VStack,
} from "@chakra-ui/react";

const HomePage = () => {
	const {
		Slide1,
		Slide2,
		Slide3,
		Consultancy,
		Training,
		Training2,
		ThreePhase,
		Surface,
		Clipboard,
	} = Png;
	return (
		<>
			<Layout>
				<div>
					<div id="full-slider-wrapper">
						<div id="layerslider" style={{ width: "100%", height: 650 }}>
							{/* first slide */}
							<div
								className="ls-slide"
								data-ls="slidedelay: 5000; transition2d:5;"
							>
								<img
									src={Slide1}
									className="ls-bg"
									alt="Slide background"
									style={{ filter: "brightness(60%)" }}
								/>
								<h3
									className="ls-l"
									style={{
										top: "50%",
										left: 60,
										fontSize: 40,
										whiteSpace: "nowrap",
										color: "#fff",
										textTransform: "uppercase",
										fontWeight: 900,
									}}
									data-ls="offsetxin:0;durationin:2000;delayin:1000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% bottom 0;offsetxout:0;rotatexout:90;transformoriginout:50% bottom 0;"
								>
									Experts in Surface Well Testing,
								</h3>

								<h3
									className="ls-l"
									style={{
										top: "60%",
										left: 60,
										fontSize: 40,
										whiteSpace: "nowrap",
										color: "#fff",
										textTransform: "uppercase",
										fontWeight: 900,
									}}
									data-ls="offsetxin:0;durationin:2000;delayin:1000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% bottom 0;offsetxout:0;rotatexout:90;transformoriginout:50% bottom 0;"
								>
									Consultancy {"&"} Training{" "}
								</h3>

								<p
									className="ls-l"
									style={{
										top: "70%",
										left: 60,
										color: "#fff",
										fontSize: 28,
										whiteSpace: "nowrap",
									}}
									data-ls="durationin:2000;delayin:1000;easingin:easeOutElastic;"
								>
									"We are poised in delivering excellence."
								</p>
								<a
									className="ls-l button_intro "
									style={{ top: "80%", left: 60, whiteSpace: "nowrap" }}
									data-ls="durationin:2000;delayin:1300;easingin:easeOutElastic;"
									href="/about-us"
								>
									About Us
								</a>
							</div>
							{/* second slide */}
							<div
								className="ls-slide"
								data-ls="slidedelay:5000; transition2d:5;"
							>
								<img
									src={Slide3}
									className="ls-bg "
									alt="Slide background"
									style={{ filter: "brightness(60%)" }}
								/>
								<h3
									className="ls-l"
									style={{
										top: "50%",
										left: 60,
										fontSize: 40,
										whiteSpace: "nowrap",
										color: "#fff",
										textTransform: "uppercase",
										fontWeight: 900,
									}}
									data-ls="offsetxin:0;durationin:2000;delayin:1000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% bottom 0;offsetxout:0;rotatexout:90;transformoriginout:50% bottom 0;"
								>
									Experts in Surface Well Testing,
								</h3>

								<h3
									className="ls-l"
									style={{
										top: "60%",
										left: 60,
										fontSize: 40,
										whiteSpace: "nowrap",
										color: "#fff",
										textTransform: "uppercase",
										fontWeight: 900,
									}}
									data-ls="offsetxin:0;durationin:2000;delayin:1000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% bottom 0;offsetxout:0;rotatexout:90;transformoriginout:50% bottom 0;"
								>
									Consultancy {"&"} Training{" "}
								</h3>

								<p
									className="ls-l"
									style={{
										top: "70%",
										left: 60,
										color: "#fff",
										fontSize: 28,
										whiteSpace: "nowrap",
									}}
									data-ls="durationin:2000;delayin:1000;easingin:easeOutElastic;"
								>
									"We are poised in delivering excellence."
								</p>
								<a
									className="ls-l button_intro "
									style={{ top: "80%", left: 60, whiteSpace: "nowrap" }}
									data-ls="durationin:2000;delayin:1300;easingin:easeOutElastic;"
									href="/about-us"
								>
									About Us
								</a>
							</div>
							{/* third slide */}
							<div
								className="ls-slide"
								data-ls="slidedelay: 5000; transition2d:5;"
							>
								<img
									src={Slide2}
									className="ls-bg"
									alt="Slide background"
									style={{ filter: "brightness(60%)" }}
								/>
								<h3
									className="ls-l"
									style={{
										top: "50%",
										left: 60,
										fontSize: 40,
										whiteSpace: "nowrap",
										color: "#fff",
										textTransform: "uppercase",
										fontWeight: 900,
									}}
									data-ls="offsetxin:0;durationin:2000;delayin:1000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% bottom 0;offsetxout:0;rotatexout:90;transformoriginout:50% bottom 0;"
								>
									Experts in Surface Well Testing,
								</h3>

								<h3
									className="ls-l"
									style={{
										top: "60%",
										left: 60,
										fontSize: 40,
										whiteSpace: "nowrap",
										color: "#fff",
										textTransform: "uppercase",
										fontWeight: 900,
									}}
									data-ls="offsetxin:0;durationin:2000;delayin:1000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% bottom 0;offsetxout:0;rotatexout:90;transformoriginout:50% bottom 0;"
								>
									Consultancy {"&"} Training{" "}
								</h3>

								<p
									className="ls-l"
									style={{
										top: "70%",
										left: 60,
										color: "#fff",
										fontSize: 28,
										whiteSpace: "nowrap",
									}}
									data-ls="durationin:2000;delayin:1000;easingin:easeOutElastic;"
								>
									"We are poised in delivering excellence."
								</p>
								<a
									className="ls-l button_intro "
									style={{ top: "80%", left: 60, whiteSpace: "nowrap" }}
									data-ls="durationin:2000;delayin:1300;easingin:easeOutElastic;"
									href="/about-us"
								>
									About Us
								</a>
							</div>
						</div>
					</div>
					{/* End layerslider */}
					<div id="get_quote">
						<div className="container">
							<div className="row">
								<div className="col-md-9">
									<h3>Are you interested in our trainings?</h3>
								</div>
								<div className="col-md-3">
									<a href="/training-services" className="btn_quote">
										Apply Now
									</a>
								</div>
							</div>
							{/* End row */}
						</div>
						{/* End container */}
					</div>

					<Box pos="relative">
						<Box
							px={{ base: "10px", lg: "200px" }}
							py={{ base: "80px", lg: "40px" }}
							pt={{ base: "40px", lg: "40px" }}
							display={{ base: "block", md: "flex" }}
							gap={{ base: "20px", lg: "40px" }}
						>
							<Box
								px={{ base: "10px", lg: "40px" }}
								height={{ base: "200px", lg: "500px" }}
								backgroundPosition={{ base: "center" }}
								width={{ base: "full", lg: "500px" }}
								bgImage={`linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('${Surface}')`}
								// display="flex"
								flexDirection="column"
								justifyContent="center"
								display={{ base: "flex", md: "none" }}
								alignItems="center"
								opacity={1}
							>
								<Box>
									<Text
										textAlign="center"
										color="white"
										fontWeight="800"
										fontSize="30px"
									>
										Our Service
									</Text>

									<Text
										textAlign="center"
										px={{ base: "10px", lg: "20px" }}
										fontSize={20}
										color="white"
									>
										Pedekson global limited provides outstanding service
									</Text>

									<Box
										display={{ base: "none", lg: "block" }}
										mt="100px"
										justifyContent="center"
									>
										<Button
											height="50px"
											color="white"
											fontSize="16px"
											bgColor="orange"
										>
											Explore Our Services
										</Button>
									</Box>
								</Box>
							</Box>

							<VStack
								mt={{ base: "30px", md: "20px" }}
								flex="2"
								pt={{ base: "10px" }}
							>
								<Text display={{ base: "none", md: "block" }} fontSize="40px">
									Our Services
								</Text>
								<Grid
									gap={10}
									// templateColumns={{
									// 	base: "repeat(1, 1fr)",
									// 	lg: "repeat(2, 1fr)",
									// }}

									templateColumns={{
										base: "repeat(auto-fit, minmax(200px, 1fr))",
										lg: "repeat(3, 1fr)",
									}}
								>
									<GridItem>
										<Box
											borderRadius="2xl"
											// width="300px"
											height="auto"
											py="20px"
											bgColor="blue.50"
										>
											<Box px="20px">
												<Text
													fontSize="20px"
													fontWeight="700"
													color="gray.700"
													pt="10px"
												>
													Surface Well Testing
												</Text>
												<Text fontSize="16px">
													Pedekson global limited provides well testing services
													covering exploration and appraisal testing, well
													clean-up and unloading services, in-line production
													testing, Extended well testing, sand control, and
													produced water treatment under the strictest HSE
													conditions.
												</Text>
											</Box>
										</Box>
									</GridItem>

									<GridItem>
										<Box
											borderRadius="2xl"
											// width="400px"
											height="auto"
											py="20px"
											bgColor="red.50"
										>
											<Box px="20px">
												<Text
													fontSize="20px"
													fontWeight="700"
													color="gray.700"
													pt="10px"
												>
													Consultancy
												</Text>
												<Text fontSize="16px">
													Our technical consulting in no small measure cuts
													across dedicated field support, well laboratory
													services, well test design and operational management,
													and the development of scalable API well software so
													that when the needs change we easily change to
													accommodate the change.{" "}
												</Text>
											</Box>
										</Box>
									</GridItem>

									<GridItem>
										<Box
											borderRadius="2xl"
											// width="400px"
											height="auto"
											py="20px"
											bgColor="yellow.50"
										>
											<Box px="20px">
												<Text
													fontSize="20px"
													fontWeight="700"
													color="gray.700"
													pt="10px"
												>
													Manpower Outsourcing
												</Text>
												<Text fontSize="16px">
													We are a leading provider of recruitment, staffing,
													and training services to the oil and gas industry. We
													deliver relevant and consistently high-quality talent
													across various levels – from entry to experienced
													professionals to meet our client’s specific needs.
												</Text>
											</Box>
										</Box>
									</GridItem>

									<GridItem>
										<Box
											borderRadius="2xl"
											// width="400px"
											// height="auto"
											py="20px"
											bgColor="purple.50"
										>
											<Box px="20px">
												<Text
													fontSize="20px"
													fontWeight="700"
													color="gray.700"
													pt="10px"
												>
													Wellhead Maintenance
												</Text>
												<Text fontSize="16px">
													With our team of skilled and experienced Engineers and
													technicians, we provide routine inspection, repair,
													and maintenance of surface wellheads.
												</Text>
											</Box>
										</Box>
									</GridItem>

									<GridItem>
										<Box
											borderRadius="2xl"
											// width="300px"
											height="auto"
											py="20px"
											bgColor="orange.50"
										>
											<Box px="20px">
												<Text
													fontSize="20px"
													fontWeight="700"
													color="gray.700"
													pt="10px"
												>
													Training and Capacity Development
												</Text>
												<Text fontSize="16px">
													We offer world-class professional training and
													certification in the following areas:
												</Text>
												<Text fontSize="16px">Slickline Operation </Text>
												<Text fontSize="16px">Basic surface well testing </Text>

												<Text fontSize="16px">
													Intermediate surface well testing{" "}
												</Text>
												<Text fontSize="16px">Well test data acquisition </Text>
												<Text fontSize="16px">Wellhead maintenance</Text>
											</Box>
										</Box>
									</GridItem>

									<GridItem>
										<Box
											borderRadius="2xl"
											// width="400px"
											height="auto"
											py="20px"
											bgColor="green.50"
										>
											<Box px="20px">
												<Text
													fontSize="20px"
													fontWeight="700"
													color="gray.700"
													pt="10px"
												>
													Surface Well Test Equipment Rental
												</Text>
												<Text fontSize="16px">
													Our extensive inventory of surface well test equipment
													including Test Separators, Choke manifolds, Pipings,
													Tanks, Pumps amongst others, helps you get the right
													equipment you need for that specific well testing job,
													when you need it and at prices, you can afford.
												</Text>
												<Text fontSize="16px">
													Our plans are flexible and our equipment are regularly
													maintained and inspected by our Engineers to ensure
													maximum efficiency.{" "}
												</Text>
											</Box>
										</Box>
									</GridItem>
								</Grid>
							</VStack>
						</Box>
					</Box>

					<Box px={{ base: "10px", lg: "60px" }} py="20">
						<Text textAlign="center" fontSize="40px" fontWeight="600">
							{" "}
							Our Equipments
						</Text>
						<Box>
							<Grid rowGap={5}  templateColumns={{ base: "", md: "repeat(3, 1fr)" }}>
								<GridItem>
									<Box
										width={{base: "full", lg: "400px"}}
										height="400px"
										bgPosition="center"
										bgSize="cover"
									>
										<Image
											border="4px"
											_hover={{ border: "2px", borderColor: "orange" }}
											borderRadius="3xl"
											borderColor="ButtonShadow"
											w="full"
											height="400px"
											src={Surface}
										/>
									</Box>
								</GridItem>

								<GridItem>
									<Box
									// mt={{base: "20px", lg: "0px"}}
											width={{base: "full", lg: "400px"}}
										height="400px"
										bgPosition="center"
										bgSize="cover"
									>
										<Image
											border="4px"
											_hover={{ border: "2px", borderColor: "orange" }}
											borderRadius="3xl"
											borderColor="ButtonShadow"
											w="full"
											height="400px"
											src={ThreePhase}
										/>
									</Box>
								</GridItem>


								<GridItem>
									<Box
									// mt={{base: "20px", lg: "0px"}}
											width={{base: "full", lg: "400px"}}
										height="400px"
										bgPosition="center"
										bgSize="cover"
									>
										<Image
											border="4px"
											_hover={{ border: "2px", borderColor: "orange" }}
											borderRadius="3xl"
											borderColor="ButtonShadow"
											w="full"
											height="400px"
											src={Slide1}
										/>
									</Box>
								</GridItem>

{/* 
								<GridItem>
									<Box
								   	// mt={{base: "20px", lg: "0px"}}
											width={{base: "full", lg: "400px"}}
										height="400px"
										bgPosition="center"
										bgSize="cover"
									>
										<Image
											border="4px"
											_hover={{ border: "2px", borderColor: "orange" }}
											borderRadius="3xl"
											borderColor="ButtonShadow"
											w="full"
											height="400px"
											src={Slide2}
										/>
									</Box>
								</GridItem>


								<GridItem>
									<Box
								   	
											width={{base: "full", lg: "400px"}}
										height="400px"
										bgPosition="center"
										bgSize="cover"
									>
										<Image
											border="4px"
											_hover={{ border: "2px", borderColor: "orange" }}
											borderRadius="3xl"
											borderColor="ButtonShadow"
											w="full"
											height="400px"
											src={Slide3}
										/>
									</Box>
								</GridItem> */}
							</Grid>
						</Box>
					</Box>
				</div>
			</Layout>
		</>
	);
};

export default HomePage;
