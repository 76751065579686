import { Layout } from "../components/layout";
import { Png } from '../assets/png'


const SurfaceWellTestPage = () => {
    const { ThreePhase, Surface, Slide3, Eq1, Eq2, Eq3, Eq4, Eq5 } = Png;
    return (
        <>


            <Layout>

                <section className="parallax_window_in short" data-parallax="scroll" data-image-src={Slide3} data-natural-width={1400} data-natural-height={350}>
                    <div id="sub_content_in">
                        <div className="container">
                            <h1>Surface Well Testing Equipment</h1>
                            <p>"Pedekson Global Limited"</p>
                        </div>
                    </div>
                </section>


                <main>
                    <div className="container margin_60">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <div id="intro">
                                    <h2>"PEDEKSON GLOBAL since 2016"</h2>
                                    <p className="lead">
                                        PEDEKSON GLOBAL NIGERIA LIMITED is engaged in the business of  Surface Well Testing, Consultancy and Training services
                                    </p>
                                </div>
                            </div>
                        </div>{/* End row */}
                        <hr />
                        <div className="row">

                            <div className="col-sm-12 col-sm-offset-1">
                                <div className="col-md-4">


                                    <div class="product-details">

                                        <div>
                                            <div className="basic-details">
                                                <div className="row">
                                                    <div className="image-column col-md-12 col-sm-6 col-xs-12">
                                                        <figure className="image-box"><img src={ThreePhase} alt style={{ width: '100%', height: '300px' }} /></figure>
                                                    </div>

                                                </div>
                                            </div>{/*End Basic Details*/}


                                        </div>


                                    </div>
                                </div>

                                <div className="col-md-4">


                                    <div class="product-details">

                                        <div>
                                            <div className="basic-details">
                                                <div className="row">
                                                    <div className="image-column col-md-12 col-sm-6 col-xs-12">
                                                        <figure className="image-box"><img src={Eq4} alt style={{ width: '100%', height: '300px' }} /></figure>
                                                    </div>

                                                </div>
                                            </div>{/*End Basic Details*/}


                                        </div>


                                    </div>
                                </div>

                                <div className="col-md-4">


                                    <div class="product-details">

                                        <div>
                                            <div className="basic-details">
                                                <div className="row">
                                                    <div className="image-column col-md-12 col-sm-6 col-xs-12">
                                                        <figure className="image-box"><img src={Surface} alt style={{ width: '100%', height: '300px' }} /></figure>
                                                    </div>

                                                </div>
                                            </div>{/*End Basic Details*/}


                                        </div>


                                    </div>
                                </div>
                            </div>{/* End row */}



                        </div>{/* End container */}

                        <div className="row">

                            <div className="col-sm-12 col-sm-offset-1">
                                <div className="col-md-4">


                                    <div class="product-details">

                                        <div>
                                            <div className="basic-details">
                                                <div className="row">
                                                    <div className="image-column col-md-12 col-sm-6 col-xs-12">
                                                        <figure className="image-box"><img src={Eq1} alt style={{ width: '100%', height: '300px' }} /></figure>
                                                    </div>

                                                </div>
                                            </div>{/*End Basic Details*/}


                                        </div>


                                    </div>
                                </div>

                                <div className="col-md-4">


                                    <div class="product-details">

                                        <div>
                                            <div className="basic-details">
                                                <div className="row">
                                                    <div className="image-column col-md-12 col-sm-6 col-xs-12">
                                                        <figure className="image-box"><img src={Eq2} alt style={{ width: '100%', height: '300px' }} /></figure>
                                                    </div>

                                                </div>
                                            </div>{/*End Basic Details*/}


                                        </div>


                                    </div>
                                </div>

                                <div className="col-md-4">


                                    <div class="product-details">

                                        <div>
                                            <div className="basic-details">
                                                <div className="row">
                                                    <div className="image-column col-md-12 col-sm-6 col-xs-12">
                                                        <figure className="image-box"><img src={Eq3} alt style={{ width: '100%', height: '300px' }} /></figure>
                                                    </div>

                                                </div>
                                            </div>{/*End Basic Details*/}


                                        </div>


                                    </div>
                                </div>
                            </div>{/* End row */}



                        </div>{/* End container */}


                        
                    </div>

                </main>{/* End main */}





            </Layout>

        </>
    )
}


export default SurfaceWellTestPage;