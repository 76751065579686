import Slide1 from './img/slides/slide_1.jpg';
import Slide2 from './img/slides/slide_2.jpg';
import Slide3 from './img/slides/slide_3.jpg';
import Consultancy from './img/consultancy.jpeg';
import Training from './img/training.jpeg';
import Training2 from './img/tra.jpeg';
import Surface from './img/surface.jpg';
import ThreePhase from './img/3phase-seperator.jpg';
import Clipboard from './img/svg_icons/shield.svg';
import Eq1 from './img/eq1.jpg';
import Eq2 from './img/eq2.jpg';
import Eq3 from './img/eq3.jpg';
import Eq4 from './img/eq4.jpg';
import Eq5 from './img/eq5.jpg';
import TransLogo from './img/logo-remove.png'






import Logo from './img/logo.png';



export const Png = {
    Slide1,
    Slide2,
    Slide3,
    Logo,
    Surface,
    Training,
    Training2,
    Consultancy,
    ThreePhase,
    Clipboard,
    Eq5,
    Eq4,
    Eq3,
    Eq2,
    Eq1,
    TransLogo
}
