import { Layout } from "../components/layout";
import { Col, Row, Select, Form, Input, Spin, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { LoadingOutlined } from '@ant-design/icons';

import { Png } from '../assets/png'
import { postApplication } from "../redux/training";


const Trainings = () => {

   

const antIcon = <LoadingOutlined style={{ fontSize: 24,color: 'white' }} spin />;


    const training = useSelector((state) => state.training);

    const { isLoading, isError, errorMessage, isSuccess} = training;


    const dispatch = useDispatch();

    const { Slide1, Slide2, Slide3, Consultancy, Training, Training2, Surface } = Png;

    const { Option } = Select;
    const courses = [
        {
            name: 'Slickline Course',
            price: '250000'
        },

        {
            name: 'Basic Surface Well Testing',
            price: '250000'
        },


        {
            name: 'Intermidiate Surface Well Testing',
            price: '350000'
        },


        {
            name: 'Data Acquisition (DAQ)',
            price: '300000'
        },

        {
            name: 'Well Head Maintenance',
            price: '200000'
        },


    ]

    const qualification = ['Degree', 'Others']

    const handleChange = (value) => {
        console.log(value, "From e ")
    }


    const handleSubmit = (values) => {
        const data = {
            ...values,
            course: courses[values.course].name,
            amount: courses[values.course].price
        }

        dispatch(postApplication(data))
    }


    useEffect(() => {

        if(!isLoading && isError){
         notification.open({
             
             message: errorMessage,
             type: 'error',
             className: 'custom-class',
             style: {
                 width: 600,
             },
         });
        }
 
        if(isSuccess){
         notification.open({
             
             message: isSuccess,
             type: 'success',
             className: 'custom-class',
             style: {
                 width: 600,
             },
         });
        }
 
     }, [isError, isLoading, errorMessage, isSuccess]);

    return (
        <>
            <Layout>

                <section className="parallax_window_in short" data-parallax="scroll" data-image-src={Slide3} data-natural-width={1400} data-natural-height={350}>
                    <div id="sub_content_in">
                        <div className="container">
                            <h1>Trainings</h1>
                            <p>"Pedekson Global Limited"</p>
                        </div>
                    </div>
                </section>

                <main>
                    <div className="container margin_60">


                        <div className="row justify-center-training " >
                            <div className="col-md-12">
                                <Form onFinish={handleSubmit} layout="vertical">
                                    <div className="row">


                                        <div className="col-md-6">
                                            <Form.Item label="First Name" name='first_name' rules={[
                                                {
                                                    required: true,
                                                    
                                                    message: 'First name is required',
                                                },

                                              
                                            ]}
                                            >
                                                <Input type='text' size="large" placeholder="Enter your first name" />
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-6">
                                            <Form.Item label="Last Name" name='last_name'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Last name is required',
                                                    },
                                                ]}
                                            >
                                                <Input type='text ' size="large" placeholder="Enter your last name" />
                                            </Form.Item>
                                        </div>
                                    </div>


                                    <div className="row">


                                        <div className="col-md-6">
                                            <Form.Item label="Email Address" name='email'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Email address is required',
                                                    },
                                                ]}
                                            >
                                                <Input size="large" type='email ' placeholder="Enter your email address " />
                                            </Form.Item>
                                        </div>



                                        <div className="col-md-6">
                                            <Form.Item label="Phone Number" name='phone_number' rules={[
                                                {
                                                    required: true,
                                                    message: 'Phone number is required',
                                                   
                                                },
                                               
                                               
                                            ]}>
                                                <Input size="large"type='number' placeholder="Enter your phone number" />
                                            </Form.Item>
                                        </div>
                                    </div>



                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item label="Surface Well Testing Course" name='course' rules={[
                                                {
                                                    required: true,
                                                    message: 'Course is required',
                                                },
                                            ]} >
                                                <Select placeholder="Select Course ">
                                                    {
                                                        courses.map(({name, price}, i) => (
                                                            <Option value={i}>{`${name} (${price})`}</Option>

                                                        ))
                                                    }
                                                </Select>

                                            </Form.Item>

                                        </div>


                                        <div className="col-md-6">
                                            <Form.Item label="Qualification" name="qualification" rules={[
                                                {
                                                    required: true,
                                                    message: 'Qualification name is required',
                                                },
                                            ]}>

                                                <Select placeholder="Select  Qualification">
                                                    {
                                                        qualification.map((data) => (
                                                            <Option value={data}>{data}</Option>

                                                        ))
                                                    }
                                                </Select>

                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="row" style={{ marginTop: '40px' }}>
                                        <div className="col-md-12">
                                            <button type="submit" className="ls-l button_intro " style={{ top: '80%', left: 60, whiteSpace: 'nowrap' }} data-ls="durationin:2000;delayin:1300;easingin:easeOutElastic;" >{isLoading ? <Spin indicator={antIcon} style={{color: 'white'}} /> : 'Apply Now'}</button>

                                        </div>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>




                </main>


            </Layout>

        </>
    )
}


export default Trainings;