
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { redirect } from "react-router-dom";

import axios from "axios";

const API_URL = "https://pedekson.com/testing/api";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  applications: []
};

const TrainingSlice = createSlice({
  name: "training",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false
      state.isError = false;
      state.errorMessage = "";
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postApplication.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      postApplication.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.weeklyAnalysisId = action.payload;
      }
    );

    builder.addCase(postApplication.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload.message || "Something went wrong";
    });


    builder.addCase(getApplications.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      getApplications.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.applications = action.payload;
      }
    );

    builder.addCase(getApplications.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload.message || "Something went wrong";
    });


    builder.addCase(updatePaymentStatus.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      updatePaymentStatus.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.applications = action.payload;
      }
    );

    builder.addCase(updatePaymentStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload.message || "Something went wrong";
    });
  },
});

export const { clearState } = TrainingSlice.actions;
export default TrainingSlice.reducer;



export const postApplication = createAsyncThunk(
  "training",
  async (data, thunkAPI) => {
    try {

      console.log(data)
      const formData = new FormData()
      formData.append('first_name', data.first_name)
      formData.append('last_name', data.last_name)
      formData.append('email', data.email)
      formData.append('phone', data.phone_number)
      formData.append('course', data.course)
      formData.append('qualification', data.qualification)
      formData.append('amount', data.amount)
      formData.append('action', 'add_applicant')
      formData.append('note', '')
      formData.append('other_name', '')
      const payload = await axios.post(`${API_URL}/applicant.php`, formData)
      console.log(payload, "kate") 
      if (payload.data.success) {
        window.location.href = `https://www.pedekson.com/payment.html?name=${data.first_name} ${data.last_name}&amount=${data.amount}&email=${data.email}&phone=${data.phone_number}&description=${data.course}(${data.amount})`
      }else{
        return thunkAPI.rejectWithValue(payload.data)
      }
    } catch (error) {
      console.log("Error", error.response.data.error.message);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const getApplications = createAsyncThunk(
  "get/applications",
  async (data, thunkAPI) => {
    try {
      const payload = await axios.post(`${API_URL}/applicant.php`, data)
      if (payload.success) {
        return payload.data.items;
      }
      else{
        return thunkAPI.rejectWithValue(payload.data)
      }
    } catch (error) {
      console.log("Error", error.response.data.error.message);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);



export const updatePaymentStatus = createAsyncThunk(
  "post/payment",
  async (data, thunkAPI) => {
    try {
      const payload = await axios.post(`${API_URL}/applicant.php`, data)
      if (payload.success) {
        return payload.data;
      }else{
        console.log(payload)
        return thunkAPI.rejectWithValue(payload.data)
      }
    } catch (error) {
      console.log("Error", error.response);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);


