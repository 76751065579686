import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";
import TrainingReducer from './training';

const store = configureStore({
    reducer: {
      training: TrainingReducer
    },
  });
  
  export default store;

  