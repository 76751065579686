import { Png } from "../assets/png";
import { useState } from "react";
import { Box } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";

export const Navbar = () => {
	const [display, setDisplay] = useState(false);

	return (
		<>
			<div className={`layer ${display ? "layer-is-visible" : ""} `} />

			<header>
				<div id="top_line">
					<div className="container">
						<div className="row">
							<div className="col-sm-4 hidden-xs">
								<span id="tag_line">"Experts since 2016"</span>
							</div>
							<div className="col-sm-8">
								<ul id="top_links">
									<li>
										<a href="tel://+234 09157662593" id="phone_top">
											+234 09157662593
										</a>
										<span id="opening">Mon - Sat 8.00/18.00</span>
									</li>
									<li className="hidden-xs">
										<a href="#">admin@pedekson.com</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<Box
						className="row"
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Box className="col-xs-3">
							<div id="logo">
								<a href="/">
									<Image
										src={Png.Logo}
										width="100"
										height="100"
										alt="Home Alarms"
									/>
								</a>
							</div>
						</Box>
						<Box className="col-xs-9">
							<a
								className="cmn-toggle-switch cmn-toggle-switch__htx open_close"
								onClick={() => setDisplay(!display)}
								href="javascript:void(0);"
							>
								<span>Menu mobile</span>
							</a>
							<div className={`main-menu ${display ? "show" : ""}`}>
								<div id="header_menu">
									<img
										src={Png.Logo}
										width={190}
										height={44}
										alt="Home Alarms"
									/>
								</div>
								<a
									href="#"
									onClick={() => setDisplay(!display)}
									className="open_close"
									id="close_in"
								>
									<i className="icon_close" />
								</a>
								<ul>
									<li>
										<a href="/">Home</a>
									</li>

									<li>
										<a href="/about-us">About Us</a>
									</li>

									<li>
										<a href="/training-services">Training</a>
									</li>
								</ul>
							</div>
						</Box>
					</Box>
				</div>
			</header>
		</>
	);
};
