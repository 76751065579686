import { Navbar } from "./navbar";
import { Image } from "@chakra-ui/react";

import { Png } from "../assets/png";

export const Layout = ({ children }) => (
	<>
		<div className="container">
			<Navbar />
		</div>

		<main>{children}</main>

		<footer>
			<div className="container">
				<div className="row ">
					<div className="col-md-4 col-sm-8">
						<a href="/">
							<Image
								src={Png.TransLogo}
								width="120px
                    "
								alt="Home Alarms"
							/>
						</a>

						<p style={{ marginTop: "0px" }}>
							PEDEKSON has been providing SURFACE WELL TESTING services to the
							Nigerian Oil and Gas sector since 2016. Our Well Test Package is
							designed to meet all industry safety and quality standards for
							sensitive operation.
						</p>
					</div>
					<div className="col-md-3 col-md-offset-1 col-sm-4">
						<h3>Discover</h3>
						<ul>
							<li>
								<a href="#0">About us</a>
							</li>
							<li>
								<a href="#0">Training</a>
							</li>
							<li>
								<a href="#0">Consultancy</a>
							</li>
							<li>
								<a href="#0">Surface Well Testing</a>
							</li>
							<li>
								<a href="#0">Contact Us</a>
							</li>
						</ul>
					</div>
					<div className="col-md-4 col-sm-12" id="contact_bg">
						<h3>Contacts</h3>
						<ul id="contact_details_footer">
							<li id="address_footer">
								Eze Wali Trade Center Eneka Road, Port-Harcourt <br /> Rivers
								State
							</li>
							<li id="phone_footer">
								<a href="tel://+23408037440318">09157662593</a> /{" "}
								<a href="tel://+2348038246734">08038246734</a>
							</li>
							<li id="email_footer">
								<a href="#0">admin@pedekson.com</a>
							</li>
						</ul>
					</div>
				</div>
				{/* End row */}
				<div id="social_footer">
					<ul>
						<li>
							<a href="#0">
								<i className="icon-facebook" />
							</a>
						</li>
						<li>
							<a href="#0">
								<i className="icon-instagram" />
							</a>
						</li>
						{/* <li><a href="#0"><i className="icon-google" /></a></li> */}
					</ul>
				</div>
			</div>
			{/* End container */}
			<div id="copy">
				<div className="container">© pedekson 2023 - All rights reserved.</div>
			</div>
			{/* End copy */}
		</footer>
		{/* E
		 */}
	</>
);
